body {
  font-family: "Assistant", sans-serif !important;
}

#nav-look{
  border-bottom: 1px solid rgb(206, 206, 222);
}

.form-group{
  width: 100%;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.bounceIn {
  animation-name: bounceIn;
}

.bounceNotification {
  animation-name: bounceIn;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}

@keyframes bounceIn {

  0%,
  30%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  30% {
    transform: scale3d(1.03, 1.03, 1.03);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  80% {
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

.main-steps {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;
  margin-top: 10px;
}

.step-number {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #2874F0;
  background-color: #FFFFFF;
  margin-right: 10px 10px;
  position: relative;
}
.step-container {
  /* max-width: 65%;
  min-width: 65%; */
  max-width: 50%;
  min-width: 50%;
  width: 100%;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 12px;
  height: 2px;
  background-color: #DBDBDB;
}



.cls-1{fill:#00a912;}
.cls-1,
.cls-2{fillRule:evenodd;}
.cls-2{fill:#fff;}

.active {
  color: #9f2089;
}

.product-delete-box {
  position: absolute;
  right: 10px;
  top: 6px;
  background: #ffffff;
  cursor: pointer;
  padding: 6px;
  border-radius: 15px;
  display: flex;
}

.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:before {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.58);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
}

.ripple:focus:before {
  transition: all 0.3s ease-out;
  opacity: 0;
  width: 160px;
  height: 160px;
  margin-top: -80px;
  margin-left: -80px;
}


.zoom-animation {
  animation: zoomInOut 2s ease-in-out infinite alternate;
  /* Animation properties */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
    /* Start at normal scale */
  }

  50% {
    transform: scale(1.1);
    /* Zoom in to 110% */
  }

  100% {
    transform: scale(1);
  }
}


.countdown-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  border: 2px solid black;
  padding: 16px 60px 16px 60px;
  border-radius: 12px;
  background-color: rgb(238, 237, 237);
}

.countdown-box {
  background-color: #000; 
  border-radius: 12px; 
  padding: 12px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  width: 80px;
}

.countdown-text {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.dot-timer{
  color: #000;
  display: flex;
  align-items: center;
}

.hour-count-text{
   font-size: 22px;
}