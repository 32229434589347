.accordion-item:first-of-type .accordion-button {}

.accordion-button {
  background-color: unset !important;
  box-shadow: unset !important;
  justify-content: center;
}

.accordion-button:after {
  margin-left: 10px;
}

.contact-us-btn .accordion-button:after {
  width: 0;
}

.utr-image {
  min-height: 150px;
  max-height: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.utr-image img {
  max-width: 130px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*------------------------- new tracking css---------------------- */
.order-tracking {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.tracking-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 300px;
  position: relative;
}

.tracking-item {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
}

.tracking-line {
  position: absolute;
  left: 6px;
  top: 24px;
  width: 2px;
  height: 95px;
}

.tracking-line.step-line {
  height: 105px;
}

.green-line {
  background: #28a745;
}

.gray-line {
  background: #ccc;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background: white;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

.completed-circle {
  background: #28a745;
  border-color: #28a745;
}

.pending-circle {
  background: #ffffff;
  border-color: #ccc;
}

.tracking-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.status {
  font-size: 14px;
  color: #999;
}

.completed .status {
  color: #28a745;
  font-weight: bold;
}

.date {
  font-size: 12px;
  color: #bbb;
}

/* ================================== */
.small-bullet .circle {
  width: 10px;
  height: 10px;
  margin-left: 2px;
}