/* .offcanvas {
  max-width: 80% !important;
} */
.offcanvas-header {
  background-color: #e7e5e5;
}
.category_title {
  border-bottom: 0.8px solid black;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
