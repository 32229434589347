.rating_box_des {
    font-size: 12px;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    width: max-content;
    padding: 0px 5px 0px 5px;
    border-radius: 5px;
    background: #388e3c;
    position: absolute;
    color: white;
    margin-top: 2px;
}

.rating_box_des i {
    font-size: 11px;
    margin-left: 3px;
    color: white;
}

.rating_num {
    font-size: 12px;
    margin-left: 27%;
    padding-left: 3px;
    color: #cdcdcd;
}

.delivery-txt {
    text-align: center;
    font-size: 12px;
    color: rgb(3, 151, 3);
    font-weight: 500;
}

.free-delivery-txt {
    text-align: center;
    font-size: 12px;
    color: rgb(3, 151, 3);
    font-weight: 500;
}