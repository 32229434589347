.cart_block {
    margin-top: 24px;
    height: calc(100vh - 82px);
    background: #f4f4f5;
    padding-top: 20px;
}
.custom-check-box input:checked {
    background-color: #ed143d;
    border-color: #ed143d;
    outline: #ed143d;

}
.custom-check-box input {
    margin: 0;
}
.custom-check-box input:focus {
    border-color: #ed143d;
    box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 19%);
}

.product-select-box {
    position: absolute;
    left: 15px;
    top: 30px;
    display: flex;
    width: 40px;
    height: 40px;
}

.itemComponents-base-quantity {
    display: inline-block;
    white-space: nowrap;
    padding: 2px 8px;
    background: #f5f5f6;
    color: #282c3f;
    font-weight: 700;
    cursor: pointer;
    border-radius: 2px;
    line-height: 16px;
}

.text-ellips {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}